/* purgecss start ignore */
@tailwind base;
@tailwind components;
/* purgecss end ignore */
@tailwind utilities;

body {
  background-color: black;
  color: white;
  font-size: 1rem; /* 16px */
  line-height: 1.5rem; /* 24px */
  font-family: ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont,
    "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif,
    "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
}
.embed-container {
  position: relative;
  padding-bottom: 56.25%;
  height: 0;
  overflow: hidden;
  max-width: 100%;
}
.embed-container iframe,
.embed-container object,
.embed-container embed {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

h1 {
  font-size: 2.25rem; /* 36px */
  line-height: 2.5rem; /* 40px */
}

h2 {
  font-size: 1.875rem; /* 30px */
  line-height: 2.25rem; /* 36px */
}

h3 {
  font-size: 1.5rem; /* 24px */
  line-height: 2rem; /* 32px */
}

h4 {
  font-size: 1.25rem; /* 20px */
  line-height: 1.75rem; /* 28px */
}

h5 {
  font-size: 1.125rem; /* 18px */
  line-height: 1.75rem; /* 28px */
}

h6 {
  font-size: 1rem; /* 16px */
  line-height: 1.5rem; /* 24px */
}

#maintenanceStage h1 {
  
}
